<template>
  <div class="TeacherList">
    <div class="pos-ab">
      <div class="tea-up">
        <div class="flex-alc between">
          <div class="title">讲师等级</div>
          <div class="crumb">首页 > 讲师库</div>
        </div>
        <el-divider class="mt-9 mb-15 h-1 back-d2"></el-divider>
        <div class="flex">
          <div
            class="tag click"
            :class="{ red: active == index }"
            v-for="(item, index) in tag"
            :key="item"
            @click="selTag(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="seat"></div>
    <div class="flex-alc wrap mt-39">
      <TeacherCard
        v-for="(item, index) in teacherList"
        :key="'tea' + index"
        :teacher="item"
        class="mb-52"
        :class="{ 'mr-53': (index + 1) % 4 != 0 }"
      ></TeacherCard>
    </div>
    <div class="flex-jsc mb-63">
      <Pagination
        :pageSize="12"
        :total="total"
        :page="page"
        @getPage="getPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import TeacherCard from "@/components/TeacherCard.vue";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "TeacherList",
  components: {
    TeacherCard,
    Pagination,
  },
  data() {
    return {
      tag: [
        "全部",
        "榜眼讲师",
        "探花讲师",
        "状元讲师",
        // "铂金讲师",
        // "钻石讲师",
        // "王牌讲师",
      ],
      active: 0, // 选中tag的下标
      total: 0, //总数据数
      teacherList: [],
      page: 1,
    };
  },

  mounted() {
    this.getTeacherData();
  },

  methods: {
    //获取分页数据
    getPage(e) {
      this.getTeacherData(e, 1);
      this.page = e;
    },
    //获取讲师列表
    getTeacherData(page = 1, type = 0) {
      this.$https
        .get("/api/lecturer/list", {
          page,
          limit: 12,
          rank: this.active,
        })
        .then((res) => {
          if (type) {
            this.teacherList = [
              ...this.teacherList,
              ...res.data.list,
            ];
          } else {
            this.teacherList = res.data.list;
          }
          this.total = res.data.total_count;
        });
    },
    //tag切换
    selTag(e) {
      this.active = e;
      this.getTeacherData();
      this.page = 1;
    },
  },
};
</script>

<style lang="less" scoped>
.TeacherList {
  width: 1200px;
  margin: auto;

  .seat {
    height: 148px;
    width: 100%;
  }
  .pos-ab {
    position: absolute;
    width: 100%;
    background: white;
    height: 148px;
    left: 0;
    .tea-up {
      width: 1200px;
      height: 132px;
      margin: auto;
      margin-top: 16px;

      .title {
        font-size: 18px;
        font-weight: 600;
        color: #000000;
      }
      .crumb {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
      }
      .tag {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        margin-right: 22px;
      }
      .red {
        color: #d30015;
      }
    }
  }
}
</style>